import { SET_EXPERIMENTS_SUCCESS } from '../../common/actions/set-experiments';

export function experiments(state = {}, action) {
  switch (action.type) {
    case SET_EXPERIMENTS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
