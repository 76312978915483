import { uniqBy } from 'lodash';
import { FETCH_CATEGORIES_SUCCESS } from '../../common/actions/fetch-categories';

export const categories = (state = [], { type, payload } = {}) => {
  switch (type) {
    case FETCH_CATEGORIES_SUCCESS:
      return uniqBy([...state, ...payload], '_id');
    default:
      return state;
  }
};
