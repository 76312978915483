import { getFromMemoryCache, saveInMemoryCache } from './memory-cache';
import { getFormFactor } from '../store/basic-params/basic-params-selectors';

export const getMemoryCachedStore = (wixCodeApi, platformAPIs, key) => {
  const isEditor = wixCodeApi.window.viewMode === 'Editor';
  const isPreview = wixCodeApi.window.viewMode === 'Preview';
  let memoryCachedStore = getFromMemoryCache(platformAPIs, key);

  if ((isEditor || isPreview) && memoryCachedStore) {
    const currentFormFactor = wixCodeApi.window.formFactor;
    const prevFormFactor = getFormFactor(memoryCachedStore);

    if (currentFormFactor !== prevFormFactor) {
      memoryCachedStore = null;
    }
  }

  return memoryCachedStore;
};

export const saveStoreStateInMemoryCache = (platformAPIs, key, state) => {
  return saveInMemoryCache(platformAPIs, key, state);
};
