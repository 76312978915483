import { FETCH_RECENT_POSTS_SUCCESS } from '../actions/fetch-recent-posts';

export default (state = [], { type, payload } = {}) => {
  switch (type) {
    case FETCH_RECENT_POSTS_SUCCESS:
      return payload;
    default:
      return state;
  }
};
