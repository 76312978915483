export function getFromMemoryCache(platformAPIs, key) {
  try {
    const item = platformAPIs.storage.memory.getItem(key);
    if (item) {
      return JSON.parse(item);
    }
    return null;
  } catch (error) {
    return null;
  }
}

export function saveInMemoryCache(platformAPIs, key, value) {
  try {
    return platformAPIs.storage.memory.setItem(key, JSON.stringify(value));
  } catch (error) {}
}
