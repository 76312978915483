import { FETCH_RECENT_POSTS_SUCCESS } from '../actions/fetch-recent-posts';
import {
  REACT_TO_POST_REQUEST,
  REACT_TO_POST_SUCCESS,
  REACT_TO_POST_FAILURE,
} from '../../app/containers/post-reactions/post-reactions-actions';
import { postReactionsReducer } from '../../app/containers/post-reactions/post-reactions-reducer';
import { fromServerReaction } from '../../app/containers/post-reactions/reaction-converters';
import { isDefined } from '../../utils/ts-utils';

const post = (state, action) => {
  switch (action.type) {
    case REACT_TO_POST_REQUEST:
    case REACT_TO_POST_SUCCESS:
    case REACT_TO_POST_FAILURE:
      return postReactionsReducer(state, action);
    default:
      return state;
  }
};

export const postsReducer = (state = [], { type, payload } = {}) => {
  switch (type) {
    case FETCH_RECENT_POSTS_SUCCESS:
      return unionPosts(state, payload);
    case REACT_TO_POST_REQUEST:
    case REACT_TO_POST_SUCCESS:
    case REACT_TO_POST_FAILURE:
      return Object.keys(state).reduce((result, current) => {
        result[current] = post(state[current], { type, payload });
        return result;
      }, {});
    default:
      return state;
  }
};

function unionPosts(oldPosts, newPosts) {
  return newPosts.reduce(
    (result, post) => {
      // In some cases corrupted content is created and Ricos fails to handle it
      // Added default values for Ricos content.
      if (!'nodes' in post.content) {
        if (!post.content.entityMap) {
          post.content.entityMap = {};
        }

        if (!post.content.blocks) {
          post.content.blocks = [];
        }
      }

      const { reactedIdentities, recentReactions, ...remainingPost } = post;
      result[remainingPost._id] = {
        ...remainingPost,
        reactions: {
          type: 'IDLE',
          reactedIdentities: reactedIdentities?.map(fromServerReaction).filter(isDefined) ?? [],
          recentReactions: recentReactions ?? [],
        },
      };
      return result;
    },
    { ...oldPosts },
  );
}
